
export default [
    {
      path: '/recruitment',
      name: 'Recruitment',
      component: () => import('@/views/recruitment/Recruitment.vue'),
      meta: {
        type: 'recruitment',
      },
      // children: [
      //   {
      //     path: "/profile/edit",
      //     name: "ProfileEdit",
      //     component:()=> ProfileEdit,
      //   },
      // ],
    },
  ];
  