<template>
  <div @click.stop="ableShowProfile ? (isShowProfile = true) : attrs?.onClick()">
    <img
      v-if="createObjectURL(selectedFile)"
      :class="[
        'rounded-full',
        {
          'hover:opacity-90 cursor-pointer': ableShowProfile,
        },
      ]"
      :src="createObjectURL(selectedFile)"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
      }"
    />
    <div
      v-else
      class="rounded-full grid place-items-center"
      :style="{
        backgroundColor: profileForm?.colorCode || '#00D696',
        borderColor: profileForm?.colorCode || '#00D696',
        width: `${size}px`,
        height: `${size}px`,
      }"
    >
      <p
        class="text-white"
        :style="{
          fontSize: `${size / 3}px`,
        }"
      >
        {{ get(profileForm, 'nickname[0]', '') }}
      </p>
    </div>
  </div>
  <BasePopup
    v-model="isShowProfile"
    title="프로필 보기"
    closeText="별점주기"
    class="profile-popup"
    :showFooter="false"
    :showHeader="false"
  >
    <ProfilePop
      v-if="isShowProfile"
      :profileForm="profileForm"
      @closeProfile="isShowProfile = false"
    />
  </BasePopup>
</template>

<script setup lang="ts">
import get from 'lodash/get';
import { watch, computed, ref, useAttrs } from 'vue';
import { Http } from '@/shared/api/api';
import { useProfileImgsStore } from '@/stores/profileImg';
import ProfilePop from '@/components/ProfilePop.vue';
import BasePopup from '@/components/BasePopup.vue';

const props = defineProps({
  profileForm: {
    type: Object,
    default: () => null,
  },
  ableShowProfile: {
    type: Boolean,
    default: () => true,
  },
  size: {
    type: Number,
    default: 36,
  },
});

const attrs = useAttrs();

const profileImgsModule = useProfileImgsStore();
const selectedFile = computed(() =>
  props.profileForm?.profileFileIdx
    ? profileImgsModule.cachedImg[props.profileForm.profileFileIdx]
    : null,
);

const createObjectURL = (file: File | null) => {
  if (!file) return '';

  try {
    if (!(file instanceof Blob)) {
      throw new Error('Invalid file type');
    }

    return URL.createObjectURL(file);
  } catch (error) {
    console.error('Error creating object URL:', error);
    return '';
  }
};

const getProfileImg = async () => {
  if (
    !props.profileForm?.profileFileIdx ||
    profileImgsModule.cachedIdx.includes(props.profileForm.profileFileIdx.toString())
  )
    return;

  profileImgsModule.cachedImg[props.profileForm.profileFileIdx] = null;

  const res = await Http.api().get(
    `/file?fileIdx=${props.profileForm?.profileFileIdx}`,
    {},
    'blob',
  );

  profileImgsModule.cachedImg[props.profileForm.profileFileIdx] = res.data;
};

const isShowProfile = ref(false);

watch(
  () => props.profileForm,
  () => {
    getProfileImg();
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped></style>
