export const API_URL_LOCAL = 'http://localhost:3002/v1';
export const API_URL_DEV ='https://api-dev.eposo.ai/v1';
export const API_URL_PROD ='https://api.eposo.ai/v1';

export enum apiStatus {
  SUCCESS = 200,
  SUCCESS_CREATED = 201,
}

export enum GlobalRole {
  ADMIN = 'Admin',
  DEPTH1 = '1Depth',
  DEPTH2 = '2Depth',
  CLIENT = 'Client',
}
