

export default [
  {
    path: '/find/id',
    name: 'FindId',
    component: () => import('@/views/find/FindId.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    },
  },
  {
    path: '/find/password',
    name: 'FindPassword',
    component: () => import('@/views/find/FindPassword.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    },
  },
  {
    path: '/find/repassword',
    name: 'FindRepassword',
    component: () => import('@/views/find/FindRepassword.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    },
  },
  // {
  //   path: "/oauth/pre",
  //   name: "auth",
  //   component:()=> existUser,
  // },
];
