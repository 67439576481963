export const getExtension = (fileName: string) => {
  const split = fileName.split('.');
  return split[split.length - 1];
};

export const createProfileImg = (profileFile: Blob) => {
  if (!(profileFile instanceof Blob)) return require('@/assets/images/icon_profile.svg');
  return URL.createObjectURL(profileFile);
};

type FileExtension = 'doc' | 'docx' | 'xls' | 'xlsx' | 'ppt' | 'pptx' | 'pdf';

export const getFileIcon = (fileName: string): string => {
  // Extract the file extension
  const extension = fileName.split('.').pop()?.toLowerCase();

  // Define a mapping of file extensions to icon imports
  const iconMap: Record<FileExtension, string> = {
    doc: require('@/assets/images/icon_word.svg'),
    docx: require('@/assets/images/icon_word.svg'),
    xls: require('@/assets/images/icon_excel.svg'),
    xlsx: require('@/assets/images/icon_excel.svg'),
    ppt: require('@/assets/images/icon_powerpoint.svg'),
    pptx: require('@/assets/images/icon_powerpoint.svg'),
    pdf: require('@/assets/images/icon_pdf.svg'),
  };

  return iconMap[extension as FileExtension] || require('@/assets/images/icon_other_file.svg');
};


export const bytesToMB = (bytes: number, decimalPlaces: number = 2): number => {
  return parseFloat((bytes / 1048576).toFixed(decimalPlaces));
};