<template>
  <div class="star">
    <div class="starRate">
      <div class="starRate__input">
        <input
          type="range"
          :value="rating"
          min="0"
          max="5"
          step="0.5"
          @input="onChange($event)"
        />
      </div>
      <div class="starRate__box">
        <span
          v-for="i in Math.floor(rating)"
          :key="i"
        >
          <img
            class="starRate__star"
            src="@/assets/images/icon_star_rate_full.svg"
          />
        </span>
        <span>
          <img
            v-if="rating % 1 !== 0"
            class="starRate__star_half"
            src="@/assets/images/icon_star_rate_half.svg"
          />
        </span>
        <span
          v-for="i in 5 - Math.floor(rating) - (rating % 1 !== 0 ? 1 : 0)"
          :key="i"
        >
          <img
            class="starRate__star"
            src="@/assets/images/icon_star_rate_empty.svg"
          />
        </span>
      </div>
    </div>
    <div class="rating">
      <span class="rating__span">{{ rating }}</span>
      <span>/5</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
  modelValue: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(['update:modelValue']);

const rating = ref(props.modelValue);

const onChange = (e: any) => {
  const value = parseFloat((e.target as HTMLInputElement).value);
  rating.value = value;
  emits('update:modelValue', rating.value);
};
</script>

<style lang="scss" scoped>
.star {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.24rem;
}
.starRate {
  position: relative;
  width: 178px;
  &__box {
    width: 178px;
    height: 30px;
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0;
    transform: translate(0, -50%);
  }
  &__star_half {
    width: 32px;
    height: 32px;
    display: flex;
    margin-left: 4px;
    flex-direction: row;
  }
  &__star {
    width: 32px;
    height: 32px;
    display: flex;
    margin-left: 4px;
    flex-direction: row;
  }
  &__input {
    width: 17.6rem;
    position: absolute;
    top: 50%;
    left: 0;
    align-items: center;
    transform: translate(0, -50%);
    z-index: 100;
    opacity: 0;
    input {
      width: 17.6rem;
    }
  }
}
.rating {
  width: 60px;
  margin-left: 12px;
  color: black;
  font-size: var(--font-14);
  font-weight: 400;
  &__span {
    width: 45px;
    font-size: var(--font-32);
  }
}
</style>
