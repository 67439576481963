export default [
  {
    path: '/permission-denied',
    name: 'Permission denied',
    component: () => import('@/views/middleware/permission.vue'),
    meta:{
      type:'No permission'
    }
  },
  {
    path: '/no-project',
    name: 'No project',
    component: () => import('@/views/middleware/NoProject.vue'),
    meta:{
      type:'No project'
    }
  },
  {
    path: '/mailauth',
    name: 'MailAuth',
    component: () => import('@/views/middleware/MailAuth.vue'),
    meta:{
      type:'MailAuth'
    }
  },
];
