import { defineStore } from 'pinia'
import { ref } from 'vue'

interface toast {
  id: number,
  text: string,
  icon?: string,
  close?: boolean,
  width?: string,
}
export const toastStore = defineStore('toast', () => {
  const toastData = ref<toast[]>([])
  const setToast = (data: any) => {
    const newToast: toast = { id: Date.now(),  text: data.text, icon: data.icon, close: data.close, width: data.width }
    console.log(toastData)
    toastData.value.push(newToast);
    setTimeout(() => {
      toastData.value = toastData.value.filter(toast => toast.id !== newToast.id );
    }, 5000);

  }
  return { toastData, setToast }
},
)