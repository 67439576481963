import { projectPermission } from '@/middleware/permission';

export default [
  {
    path: '/document',
    name: 'document',
    component: () => import('@/views/document/Document.vue'),
    meta: { type: 'document',middleware: [projectPermission] },
  },
];
