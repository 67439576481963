import { DirectiveBinding } from 'vue';

interface HTMLElementWithClickOutsideEvent extends HTMLElement {
  clickOutsideEvent: (event: MouseEvent) => void;
}
const clickOutSide = {
  mounted(el: HTMLElementWithClickOutsideEvent, binding: DirectiveBinding) {
    el.clickOutsideEvent = function (event: MouseEvent) {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el: HTMLElementWithClickOutsideEvent) {
    if(!document.body) return;
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};

export default clickOutSide;
