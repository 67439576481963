<template>
  <div class="c-radiobox">
    <input
      type="radio"
      class="c-radiobox__input"
      :value="modelValue"
      @change="updateValue"
      :name="name"
      :id="id"
      :checked="checked"
      :disable="disabled"
    />
    <label
      :for="id"
      :class="`
        c-radiobox__label
        c-radiobox__label--${color}
      `"
      :id="id"
    >
      <slot />
    </label>
  </div>
</template>

<script setup lang="ts">

defineProps({
  modelValue: {
    type: String,
    default: "value",
  },
  name: {
    type: String,
    default: "name",
  },
  id: {
    type: String,
    default: "id",
  },
  checked: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "default",
  },
});

// tobe
const emit = defineEmits(["update:modelValue"]);

const updateValue = (e: Event) => {
  emit("update:modelValue", (e.target as HTMLInputElement).value);
};
</script>

<style lang="scss" scoped>
.c-radiobox {
  &__input {
    position: absolute;
    top: -999999em;
  }
  &__label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: var(--radius-4);
    position: relative;
    &--default {
      padding-left: 2.5rem;
      font-size: 1.6rem;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      user-select: none;
      display: inline-block;
      width: auto;
      height: auto;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        background: url(@/assets/images/icon_checkbox.svg) center / contain
          no-repeat;
      }
      &::after {
        content: none;
      }
    }
    &--green {
      background-color: var(--person-10);
    }
    &--skyblue {
      background-color: var(--person-20);
    }
    &--purple {
      background-color: var(--person-30);
    }
    &--yellow {
      background-color: var(--person-40);
    }
    &--red {
      background-color: var(--person-50);
    }
    &--blue {
      background-color: var(--person-60);
    }
    &--orange {
      background-color: var(--person-70);
    }
    &--black {
      background-color: var(--main-10);
    }
    &--skyblue {
      background-color: var(--person-20);
    }
    &--purple {
      background-color: var(--person-30);
    }
    &--yellow {
      background-color: var(--person-40);
    }
    &--red {
      background-color: var(--person-50);
    }
    &--blue {
      background-color: var(--person-60);
    }
    &--orange {
      background-color: var(--person-70);
    }
    &--black {
      background-color: black;
    }
  }
  &__label:hover {
    cursor: pointer;
  }
  &__input:checked + &__label--default {
    &::before {
      background: url(@/assets/images/icon_checkbox_active.svg) center / contain
        no-repeat;
    }
  }
  &__input:checked + &__label {
    &::after {
      content: "";
      width: 1.2rem;
      height: 1.1rem;
      position: absolute;
      background: url(@/assets/images/icon_check_white.svg) center / contain
        no-repeat;
    }
  }
}
</style>
