import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type { Ref } from 'vue';

// Define the type for the cached image object
type CachedImg = Record<string, File | null> ;

export const useProfileImgsStore = defineStore('profileImgs', () => {
  const cachedImg: Ref<CachedImg> = ref({});
  const cachedIdx = computed(() => Object.keys(cachedImg.value));

  const reset = () => {
    cachedImg.value = {};
  };

  return { cachedImg, cachedIdx, reset };
});
