<template>
  <div class="star-pop">
    <div class="star-pop__input_container">
      <div class="star-pop__star_input"><StarInput v-model="formReview.rating" /></div>
      <div class="star-pop__comment_input">
        <textarea
          v-model="formReview.content"
          placeholder="내용을 입력하세요."
        ></textarea>
      </div>
      <div class="star-pop__star-image-container">
        <div
          class="star-pop__star-image"
          v-for="(imagesrc, index) in imageListSrc"
          :key="index"
          alt="image"
        >
          <img
            class="star-pop__star-image-box"
            :src="createObjectURL(imagesrc)"
          />
          <img
            class="star-pop__star-discard-icon"
            src="@/assets/images/icon_image_discard.svg"
            @click="discardImage(index)"
          />
        </div>
        <div
          v-for="x in blankCount"
          :key="x"
        >
          <div
            class="star-pop__star-blank-box"
            @click="handleUploadClick"
          >
            <img
              class="star-pop__star-blank-icon"
              src="@/assets/images/icon_image_plus.svg"
            />
          </div>
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            hidden
            @change="uploadImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { createReview } from '@/api/review';
import StarInput from './StarInput.vue';
const imageListSrc = ref<File[]>([]);
const blankCount = ref(3 - imageListSrc.value.length);
import { projectStore } from '@/stores/project';

import { uploadFileToProject } from '@/api/file';

const props = defineProps({
  memberIdx: {
    type: Number,
    default: null,
  },
});

const moduleProject = projectStore();

const formReview = ref({
  content: '',
  rating: 1,
  medias: [],
});
const handleUploadClick = () => {
  const fileInput = document.getElementById(`fileInput`);
  if (fileInput) {
    fileInput.click();
  }
};

const uploadImage = async (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files && files.length > 0) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await uploadFileToProject(
          moduleProject.selectedProjectId as number,
          formData,
        );
        console.log(response);
        formReview.value.medias.push(response.data.fileIdx);
      } catch (e) {
        console.error('Error uploading file:', e);
      }
    };
    reader.readAsDataURL(file);
    imageListSrc.value.push(files[0]);
    blankCount.value = blankCount.value - 1;
  }
};

const handleCreateReview = async () => {
  try {
    const response = await createReview({ memberIdx: props.memberIdx || 1, ...formReview.value });
    console.log(response);
  } catch (error) {
    console.error(error);
  }
};

const discardImage = (index: number) => {
  formReview.value.medias.splice(index, 1);
  imageListSrc.value.splice(index, 1);
  blankCount.value = blankCount.value + 1;
};

const createObjectURL = (file: File | null) => {
  if (!file) return require('@/assets/images/image_star_rate_sample.png');
  return URL.createObjectURL(file);
};

defineExpose({
  handleCreateReview,
});
</script>

<style lang="scss" scoped>
.star-pop {
  &__input_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1.5rem;
  }
  &__star_input {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__comment_input {
    display: flex;
    width: 100%;
    margin: 24px 0;
    height: 8.25rem;
    border-radius: 4px;
    padding: 11px 14px;
    font-size: var(--font-14);
    border: 1px solid var(--grey-scale-3, #dfdfdf);
    color: #535353;
  }
  &__star-image-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }
  &__star-image-box {
    width: 20rem;
    height: 11.15rem;
    border-radius: 4px;
    background: #ededed;
  }
  &__star-image {
    position: relative;
  }
  &__star-blank-box {
    display: flex;
    width: 20rem;
    height: 11.15rem;
    border-radius: 0.25rem;
    background: #ededed;
    justify-content: center;
    align-items: center;
  }
  &__star-button {
    margin-top: 2rem;
    color: var(--main-color-shades-2, #00544a);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }
  &__star-blank-icon {
    width: 3rem;
    height: 3rem;
    justify-content: center;
  }
  &__star-discard-icon {
    display: flex;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 3rem;
    height: 3rem;
  }
}
</style>
