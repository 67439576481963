

export default [
  {
    path: '/login',
    name: 'login',
    component:()=> import('@/views/login/Login.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    },
  },
  {
    path: '/oauth/pre',
    name: 'authNoUser',
    component:()=> import('@/views/login/NoUser.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    },
  },
  {
    path: '/oauth/success',
    name: 'authExistUser',
    component:()=> import('@/views/login/ExistUser.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    },
  },
  {
    path: '/mailform',
    name: 'MailForm',
    component:()=> import('@/views/login/MailForm.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    }
  },
  // {
  //   path: "/oauth/pre",
  //   name: "auth",
  //   component:()=> existUser,
  // },
];
