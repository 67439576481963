import { projectPermission } from '@/middleware/permission';

export default [
  {
    path: '/calculate/expert/:id',
    name: 'calculatExpertById',
    component: () => import('@/views/calculate/ExpertCalculate.vue'),
    meta: { type: 'calculate' },
    props: true,
  },
  {
    path: '/calculate/client/:id',
    name: 'calculateClientById',
    component: () => import('@/views/calculate/ExpertCalculate.vue'),
    meta: { type: 'calculate' },
    props: true,
  },
];
