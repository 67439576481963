<template>
  <div class="profile-pop last:mb-2 relative">
    <i
      @click="$emit('closeProfile')"
      class="c-icon c-icon--close absolute right-0 top-5 cursor-pointer"
    >
      <span class="s-hide">close</span>
    </i>
    <!-- 프로필 컨테이너 -->
    <div class="profile-pop__card">
      <div class="profile-pop__profile-box">
        <DefaultProfileImage
          :profileForm="profileForm"
          :ableShowProfile="false"
          :size="140"
        />
      </div>
      <div class="profile-pop__profile-name">{{ profileInfo.nickname }}</div>
      <div class="profile-pop__title-box"></div>
      <template v-if="moduleRole.isAdmin">
        <div class="profile-pop__subtitle-box">
          <span class="profile-pop__card-subtitle">이메일</span>
          <div class="profile-pop__card-subcontent">{{ profileInfo.email }}</div>
        </div>
        <div class="profile-pop__subtitle-box">
          <span class="profile-pop__card-subtitle">이름</span>
          <span class="profile-pop__card-subcontent">{{ profileInfo.name }}</span>
        </div>
        <div class="profile-pop__subtitle-box">
          <span class="profile-pop__card-subtitle">휴대폰번호</span>
          <span class="profile-pop__card-subcontent">{{ profileInfo.phone }}</span>
        </div>
        <div class="profile-pop__subtitle-box">
          <span class="profile-pop__card-subtitle">생년월일</span>
          <span class="profile-pop__card-subcontent">{{
            moment(profileInfo.birth).format('YYYY.MM.DD')
          }}</span>
        </div>
        <div class="profile-pop__subtitle-box">
          <span class="profile-pop__card-subtitle">성별</span>
          <span class="profile-pop__card-subcontent">{{
            profileInfo.gender == 'M' ? $t('views.profile', 'male') : $t('views.profile', 'female')
          }}</span>
        </div>
      </template>
      <div class="profile-pop__subtitle-box">
        <span class="profile-pop__card-subtitle">퍼스널컬러</span>
        <span class="profile-pop__card-subcontent flex gap-2">
          <PersonalColor :select-color="profileInfo.colorCode" />
        </span>
      </div>
      <div class="profile-pop__title-box">
        <span class="profile-pop__card-title">업무</span>
      </div>
      <div class="profile-pop__subtitle-box">
        <span class="profile-pop__card-subtitle">직무</span>
        <span class="profile-pop__card-subcontent">{{ profileInfo.job }}</span>
      </div>
      <div class="profile-pop__subtitle-box">
        <span class="profile-pop__card-subtitle">업무성격</span>
        <span class="profile-pop__card-subcontent">{{
          profileForm.isFree == null ? '프리랜서' : '정규직'
        }}</span>
      </div>
      <template v-if="moduleRole.isAdmin">
        <div class="profile-pop__subtitle-box">
          <span class="profile-pop__card-subtitle">희망 일당</span>
          <span class="profile-pop__card-subcontent"
            >{{ profileInfo.hopePrice }}
            <span
              v-if="profileInfo.isFree == null"
              class="profile-pop__card-subcontent-money"
              >만원/8시간</span
            >
            <span
              v-else
              class="profile-pop__card-money"
              >만원/8시간</span
            ></span
          >
        </div>
      </template>
    </div>
    <!-- 별점 컨테이너 -->
    <div v-if="false" class="profile-pop__card">
      <div class="profile-pop__title-box">
        <div class="flex items-center gap-4">
          <span class="profile-pop__card-title-starRate">별점</span>
          <BaseButton
            color="outline"
            size="small"
            @click="isStarPopup = true"
          >
            별점 주기
          </BaseButton>
        </div>
        <div class="profile-pop__star_total">
          <div class="profile-pop__star_total_text">{{ profileInfo?.rating?.toFixed(1) }}</div>
          /5
          <div class="profile-pop__star_icon">
            <StarRate :id="profileInfo?.rating?.toFixed(1)"></StarRate>
          </div>
        </div>
      </div>
      <div
        v-for="review in reviews"
        :key="review.reviewMemberIdx"
        class="profile-pop__star-container"
      >
        <div class="profile-pop__star-box">
          <div class="profile-pop__star-topbar">
            <div class="profile-pop__star-rating">
              <StarRate :id="review.rating" />
            </div>
            <div class="profile-pop__star-date">
              {{ $t('common', 'formatDt')(review.createdAt, 'YYYYMMDDHHmmss', 'YYYY.MM.DD') }}
            </div>
          </div>
        </div>
        <div class="profile-pop__star-comment">{{ review.content }}</div>
        <div class="profile-pop__star-image-container">
          <div
            v-for="(src, index) in review.medias"
            :key="index"
            alt="image"
          >
            <img
              class="profile-pop__star-image-box"
              :src="src"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 경력 컨테이너 -->
    <div class="profile-pop__card">
      <span class="profile-pop__card-title">경력</span>
      <div
        class="profile-pop__job-box"
        v-for="(career, index) in profileInfo.career"
        :key="index"
      >
        <div class="profile-pop__subtitle-box">
          <span class="profile-pop__job-subtitle-date">
            {{ career.startDt + ' ~ ' + career.endDt }}
          </span>
          <span class="profile-pop__job-subcontent">{{ career.company }}</span>
        </div>
        <div class="profile-pop__subtitle-box">
          <div class="profile-pop__job-position">
            <span class="profile-pop__job-position-subtitle">직급/직무</span>
            <span class="profile-pop__job-position-subcontent">{{ career.level }}</span>
          </div>
          <div class="profile-pop__job-salary">
            <span class="profile-pop__job-subtitle">연봉</span>
            <span class="profile-pop__job-subcontent-salary">{{ career.salary }}</span>
          </div>
        </div>
        <div class="profile-pop__subtitle-box-end">
          <span class="profile-pop__job-subtitle">업무</span>
          <span
            class="profile-pop__job-workcontent"
            v-html="career.description.replace(/\n/g, '<br />')"
          ></span>
        </div>
      </div>
    </div>
  </div>
  <BasePopup
    v-model="isStarPopup"
    title="별점 주기"
    confirmText="별점 주기"
    @confirm="
      async () => {
        await startPost.handleCreateReview();
        handleGetReviews();
      }
    "
  >
    <StarPost
      :memberIdx="profileForm.memberIdx"
      ref="startPost"
    />
  </BasePopup>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import moment from 'moment';
import { getMemberProfile } from '@/api/member';
// import { getReviews } from '@/api/review';

// import { getUrlFile } from '@/api/file';

import StarRate from '@/components/StarRate.vue';
import DefaultProfileImage from '@/components/DefaultProfileImage.vue';
import PersonalColor from '@/components/PersonalColor.vue';
import BaseButton from '@/components/BaseButton.vue';
import BasePopup from '@/components/BasePopup.vue';
import StarPost from '@/components/StarPost.vue';
import { roleStore } from '@/stores/role';

import { $t } from '@/plugins/translation';

const moduleRole = roleStore();

const props = defineProps({
  profileForm: {
    type: Object,
    default: () => ({}),
  },
});

const isStarPopup = ref(false);
const startPost = ref(null);

const reviews = ref([]);

const profileInfo = ref({});

const handleGetProfile = async () => {
  try {
    const res = await getMemberProfile({
      memberId: props.profileForm?.memberId,
    });
    profileInfo.value = res.data;
   } catch (error) {
    console.error(error);
  }
};
// const handleGetReviews = async () => {
//   try {
//     const res = await getReviews({
//       memberIdx: profileInfo.value.memberIdx,
//     });

//     reviews.value = await Promise.all(
//       res.data.data.map(async (review) => {
//         if (review.medias?.length) {
//           const medias = await Promise.all(
//             review.medias?.map(async (fileIdx) => await handleGetUrlFile(fileIdx)),
//           );

//           return {
//             ...review,
//             medias,
//           };
//         }
//         return review;
//       }),
//     );
//   } catch (error) {
//     console.error(error);
//   }
// };
// const handleGetUrlFile = async (fileIdx) => {
//   try {
//     const response = await getUrlFile({
//       fileIdx,
//     });
//     return response.data.url;
//   } catch (e) {
//     console.error(e);
//   }
// };
handleGetProfile();
</script>

<style lang="scss" scoped>
.profile-pop {
  display: flex;
  flex-flow: column;
  /* justify-content: center; */
  align-items: flex-start;
  overflow-x: hidden;
  &__card {
    width: 620px;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);
    padding: 4rem 4rem 5.6rem;
  }
  &__card-title {
    font-size: var(--font-18);
    font-weight: bold;
  }
  &__profile-box {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    margin-bottom: 16px;
    color: var(--white);
  }
  &__profile {
    width: 14rem;
    height: 14rem;
    border: 1px solid var(--gray-20);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 8rem;
      height: 8rem;
    }
    &-img {
      border-radius: 50%;
    }
  }
  &__blank-image {
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
  }
  &__profile-name {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: var(--font-18);
    color: var(--main-shade-10);
    font-weight: 500;
    margin-bottom: 12px;
  }
  &__modify {
    position: absolute;
    right: 0;
  }
  &__list {
    display: flex;
    align-items: flex-end;
    gap: 1.6rem;
    & + & {
      margin-top: 3.2rem;
    }
  }
  &__box {
    flex: 1;
  }
  &__phone-box {
    display: flex;
    gap: 0.8rem;
    flex: 1;
    .c-dropdown {
      width: 9.2rem;
      &__menu {
        max-height: 300px;
        overflow-y: scroll;
        &-btn {
          padding: 0.8rem 1rem;
        }
      }
    }
    .c-form {
      width: calc(100% - 10rem);
    }
  }
  &__label {
    display: block;
    font-size: var(--font-14);
    font-weight: var(--font-medium);
    margin-bottom: 1.6rem;
    &--star {
      &::after {
        content: '*';
        display: inline-block;
        color: var(--state-10);
      }
    }
  }
  &__color {
    display: flex;
    gap: 0.4rem;
    padding: 0.4rem 0;
  }
  &__title-box {
    border-bottom: 2px solid var(--main-30);
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  &__subtitle-box {
    width: 100%;
    border-bottom: 1px solid var(--gray-30);
    padding: 18px 0rem;
    display: flex;
    align-items: center;
  }
  &__subtitle-box-end {
    width: 100%;
    padding: 14px 0;
    display: flex;
    flex-direction: row;
  }
  &__job-box {
    border-top: 2px solid var(--main-30);
    margin-top: 20px;
    padding-bottom: 20px;
  }
  &__job-position {
    display: flex;
    width: 76%;
    font-size: var(--font-13);
    font-weight: normal;
  }
  &__job-salary {
    display: flex;
    align-items: end;
    justify-content: end;
    width: 24%;
    font-size: var(--font-13);
    font-weight: normal;
  }
  &__card-title {
    margin-top: 40px;
    font-size: var(--font-16);
    font-weight: bold;
  }
  &__card-title-starRate {
    font-size: var(--font-16);
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
  }
  &__card-subtitle {
    width: 62px;
    font-size: var(--font-14);
    font-weight: normal;
    margin-right: 83px;
  }
  &__job-position-subtitle {
    font-size: var(--font-14);
    font-weight: normal;
    margin-right: 52px;
  }
  &__job-position-subcontent {
    font-size: var(--font-16);
    font-weight: normal;
  }
  &__card-subcontent {
    font-size: var(--font-16);
    font-weight: normal;
  }
  &__card-money {
    font-size: var(--font-13);
    font-weight: normal;
  }
  &__job-subtitle {
    font-size: var(--font-14);
    display: flex;
    font-weight: normal;
  }
  &__job-subtitle-date {
    font-size: var(--font-16);
    font-weight: normal;
  }
  &__job-subcontent {
    margin-left: 25px;
    font-size: var(--font-16);
    font-weight: normal;
  }
  &__job-subcontent-salary {
    font-size: var(--font-16);
    justify-content: start;
    text-align: end;
    font-weight: normal;
    margin-left: 62px;
  }
  &__job-workcontent {
    font-size: var(--font-16);
    font-weight: normal;
    line-height: 2rem;
    margin-left: 80px;
  }
  &__card-subcontent-money {
    font-size: var(--font-13);
    font-weight: normal;
    line-height: 1.5rem;
    letter-spacing: -0.01rem;
  }
  &__inputbox {
    display: flex;
    align-items: center;
    position: relative;
    .c-form {
      width: calc(50% - 0.8rem);
      &__input {
        text-align: right;
        font-weight: 700;
      }
    }
  }
  &__date {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: var(--font-13);
  }
  &__sub-txt {
    font-size: var(--font-13);
    vertical-align: middle;
    margin-left: 0.8rem;
  }
  &__career-btn {
    display: flex;
    padding-left: 3rem;
    position: relative;
    font-size: var(--font-14);
    .c-icon {
      position: absolute;
      left: 0;
      top: calc(50% - 1.5rem);
    }
    .c-icon--minus--main-3 {
      width: 2rem;
      height: 2rem;
      top: -2px;
    }
  }
  &__add {
    display: flex;
    justify-content: center;
    margin-top: 2.4rem;
  }
  &__bottom {
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    gap: 1.6rem;
    padding: 1.6rem 0;
  }
  &__star-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
  }
  &__star-box {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  &__star-topbar {
    width: 100%;
    height: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 20px;
  }
  &__star-rating {
    width: 80.8px;
    height: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  &__star-date {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: var(--font-13);
    color: var(--gray-10);
  }
  &__star-comment {
    display: flex;
    width: 100%;
    color: var(--comment);
    font-size: var(--font-14);
    font-style: normal;
    font-weight: 400;
    margin-top: 24px;
  }
  &__star-image-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    margin-top: 12px;
  }
  &__star-image-box {
    height: 11.15rem;
    border-radius: 4px;
    background: var(--gray-40);
  }
  &__star-button {
    margin-top: 32px;
    color: var(--main-color-shades-2);
    font-size: var(--font-14);
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }
  &__star_total {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    color: var(--black-white-01, #000);
    font-size: var(--font-14);
    font-style: normal;
    font-weight: 400;
  }
  &__star_total_text {
    color: var(--black-white-01, #000);
    font-size: var(--font-32);
    font-style: normal;
    font-weight: 400;
    margin-bottom: 9px;
  }
  &__star_icon {
    width: 101px;
    height: 20px;
    margin-left: 11px;
  }
}
</style>
