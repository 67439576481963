
export default [
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      type: 'profile',
    },
    // children: [
    //   {
    //     path: "/profile/edit",
    //     name: "ProfileEdit",
    //     component:()=> ProfileEdit,
    //   },
    // ],
  },
];
