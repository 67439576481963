

export default [
  {
    path: "/join",
    name: "join",
    component:()=> import('@/views/join/Join.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    }
  },
];