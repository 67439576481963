import get from 'lodash/get';
import { regionStore } from '@/stores/regionStore';
import { STRINGS } from '@/strings';

// type StringOrFunction = string | ((...args: any[]) => string);

export const $t = (screen: string, key: string, fallbackValue: string = '') => {
  const region = regionStore();
  const currentLang = region.lang;
  const localizedStrings = STRINGS[currentLang];
  const result = get(localizedStrings, `${screen}.${key}`, fallbackValue);

  return result || fallbackValue;
};
