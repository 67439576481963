import { DirectiveBinding } from 'vue';

interface InfiniteScrollOptions {
  handler: () => void;
  root?: Element | null;
  threshold?: number;
  rootMargin?: string;
}

const infiniteScroll = {
  mounted(el: HTMLElement, binding: DirectiveBinding<InfiniteScrollOptions>) {
    const options: IntersectionObserverInit = {
      root: binding.value?.root || null,
      threshold: binding.value?.threshold || 1.0,
      rootMargin: binding.value?.rootMargin || '0px',
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        binding.value.handler();
      }
    }, options);

    el._infiniteScrollObserver = observer;
    observer.observe(el);
  },

  unmounted(el: HTMLElement) {
    if (el._infiniteScrollObserver) {
      el._infiniteScrollObserver.disconnect();
    }
  },
};

export default infiniteScroll;
