export default [
  {
    path: "/verify/email",
    name: "verifyEmail",
    component:()=> import('@/views/verify/Email.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    }
  },
  {
    path: "/verify/invite",
    name: "verifyInvite",
    component:()=> import('@/views/verify/Invite.vue'),
    meta: {
      isHeader: false,
      isLeft: false,
    }
  },
];