import { Http } from '@/shared/api/api';

enum ReviewEndpoints {
  REVIEW_MEMBER = '/review-member',
  REVIEW_MEMBER_OF_MEMBER = '/review-member/of-member',
}

interface Params {
  [key: string]: any;
}

interface Data {
  [key: string]: any;
}
export const getReviews = async (params: Params) => {
  try {
    const response = await Http.api().get(`${ReviewEndpoints.REVIEW_MEMBER_OF_MEMBER}`, params);
    return response;
  } catch (error) {
    console.error('Error get request list:', error);
    throw error;
  }
};

export const createReview = async (data: Data) => {
  try {
    const response = await Http.api().post(`${ReviewEndpoints.REVIEW_MEMBER}`, data);
    return response;
  } catch (error) {
    console.error('Error crate request:', error);
    throw error;
  }
};

export const updateReview = async (reviewIdx: number, data: Data) => {
  try {
    const response = await Http.api().patch(`${ReviewEndpoints.REVIEW_MEMBER}/${reviewIdx}`, data);
    return response;
  } catch (error) {
    console.error('Error crate request:', error);
    throw error;
  }
};
export const deleteReview = async (reviewIdx: number) => {
  try {
    const response = await Http.api().delete(`${ReviewEndpoints.REVIEW_MEMBER}/${reviewIdx}`);
    return response;
  } catch (error) {
    console.error('Error delete request:', error);
    throw error;
  }
};
