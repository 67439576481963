import { Http } from '@/shared/api/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { profileStore } from './profile';

const nationMap: any = {
  kr: 'KR',
};

const availableLang = ['KR', 'EN'];

export const regionStore = defineStore(
  'region',
  () => {
    const lang = ref('EN');

    const setLang = async () => {
      const user = profileStore();
      if (user.profile.memberIdx && user.profile.nationality) {
        const ret = nationMap[user.profile.nationality];
        lang.value = ret || 'EN';
        return lang.value;
      }
      Http.api()
        .get('/common/region')
        .then((res) => {
          if (res && res.data) {
            lang.value = availableLang.includes(res.data.isoCode) ? res.data.isoCode : 'EN';
          }
        });
    };

    return { lang, setLang };
  },
  { persist: true },
);
