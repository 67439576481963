<template>
  <transition name="fade">
    <div
      v-if="modelValue"
      :class="`${classPopup} c-popup`"
    >
      <div
        type="button"
        class="c-popup__dim"
        @click="closePopup"
      />
      <div class="c-popup__inner">
        <div
          v-if="showHeader"
          class="c-popup__header"
        >
          <span class="c-popup__title">
            {{ title }}
          </span>
          <button
            type="button"
            @click="closePopup"
          >
            <i class="c-icon c-icon--close">
              <span class="s-hide">close</span>
            </i>
          </button>
        </div>
        <div :class="`c-popup__body ${nonScroll ? 'c-popup__body--none-scroll' : ''}`">
          <slot />
        </div>
        <div
          v-if="showFooter"
          class="c-popup__footer"
        >
          <slot name="footer">
            <BaseButton
              color="outline"
              size="small"
              @click="closePopup"
              >{{ closeText }}</BaseButton
            >
            <BaseButton
              size="small"
              @click="confirm"
              :disabled="confirmButtonDisabled"
              >{{ confirmText }}</BaseButton
            >
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import { $t } from '@/plugins/translation';

const props = defineProps({
  confirmButtonDisabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: 'title',
  },
  confirmText: {
    type: String,
    default: () => $t('common', 'confirm'),
  },
  closeText: {
    type: String,
    default: () => $t('common', 'cancel'),
  },
  classPopup: {
    type: String,
    default: '',
  },
  showFooter: {
    type: Boolean,
    default: true,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  nonScroll: {
    type: Boolean,
    default: false,
  },
  onClose: Function,
  onConfirm: Function,
});
const emit = defineEmits(['update:modelValue', 'confirm']);
const closePopup = () => {
  emit('update:modelValue', false);
  if (props.onClose) {
    props.onClose();
  }
};
const confirm = () => {
  emit('update:modelValue', false);
  if (props.onConfirm) {
    props.onConfirm();
  } else emit('confirm');
};
</script>

<style lang="scss">
.c-popup {
  $this: '.c-popup';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &.full-popup {
    .c-popup__inner {
      min-width: 80%;
      max-width: 80%;
    }
  }

  &.link-popup {
    .c-popup__inner {
      min-width: 60rem;
    }
  }

  &.history-popup {
    .c-popup__inner {
      min-width: 70%;
      max-width: 70%;
    }
  }

  &.profile-popup {
    .c-popup__inner {
      min-width: 620px;
      max-width: 620px;
    }
    .c-popup__body {
      border-radius: 10px;
    }
  }

  &__dim {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__inner {
    min-width: 60rem;
    max-width: 80rem;
    max-height: 80vh;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    z-index: 20;
    display: flex;
    flex-flow: column;
  }

  &__header {
    background-color: var(--gray-50);
    padding: 1.8rem 2.4rem 1.8rem 2.4rem;
    border-radius: 0.8rem 0.8rem 0 0;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: var(--font-18);
    color: var(--main-10);
    font-weight: 500;
  }

  &__body {
    position: relative;
    background-color: var(--white);
    // overflow-y: visible;
    height: 100%;
    max-height: 67vh;
    overflow-y: auto;

    &--none-scroll {
      overflow-y: unset;
    }
  }

  &__wrap {
    padding: 4rem;
    gap: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & .c-form {
      width: 32rem;
    }
  }

  &__label {
    width: 10rem;
    color: var(--label);
    font-size: var(--font-14);
    font-weight: var(--font-medium);
    line-height: 2.2rem;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem 0;
    gap: 1.2rem;
    border-radius: 0 0 0.8rem 0.8rem;
    border-top: 1px solid #d9d9d9;
    background-color: var(--gray-50);
    position: relative;
  }
}

// tobe animation
</style>
