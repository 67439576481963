import { Http } from '@/shared/api/api';
import { userStore } from '@/stores/authStore';

enum FileEndpoints {
  File = '/file',
}
interface Params {
    [key: string]: any;
  }

export const uploadFileToProject = async (projectIdx: number, formData: any) => {
  try {
    const userAuth = userStore();
    const response = await Http.api().post(`${FileEndpoints.File}/${projectIdx}/media`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${userAuth.key}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error crate request:', error);
    throw error;
  }
};
export const getUrlFile = async (params: Params) => {
  try {
    const response = await Http.api().get(`${FileEndpoints.File}/url`, params);
    return response;
  } catch (error) {
    console.error('Error get request list:', error);
    throw error;
  }
};
