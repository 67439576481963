<template>
  <transition name="fade">
    <div
      v-if="modelValue"
      class="c-popup"
    >
      <div
        type="button"
        class="c-popup__dim"
        @click="closePopup"
      ></div>
      <div class="c-popup__inner">
        <div class="c-popup__header">
          <span class="c-popup__title">
            {{ title }}
          </span>
          <button
            v-if="showClose"
            type="button"
            @click="closePopup"
          >
            <i class="c-icon c-icon--close">
              <span class="s-hide">close</span>
            </i>
          </button>
        </div>
        <div class="c-popup__body p-[30px] text-center">
          <p class="text-[16px]">
            {{ desc }}
          </p>
        </div>
        <div class="c-popup__footer">
          <BaseButton
            color="outline"
            size="small"
            @click="closePopup"
          >
            {{ closeText }}
          </BaseButton>
          <BaseButton
            size="small"
            @click="confirm"
          >
            {{ confirmText }}
          </BaseButton>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup lang="ts">
// import { ref } from 'vue';
import BaseButton from '@/components/BaseButton.vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  desc: {
    type: String,
    default: 'title',
  },
  confirmText: {
    type: String,
    default: '확인',
  },
  closeText: {
    type: String,
    default: '취소',
  },
  showClose: {
    type: Boolean,
    default: true,
  },
  onClose: Function,
  onConfirm: Function,
});
const emit = defineEmits(['update:modelValue']);
const closePopup = () => {
  emit('update:modelValue', false);
  if (props.onClose) {
    props.onClose();
  }
};
const confirm = () => {
  emit('update:modelValue', false);
  if (props.onConfirm) {
    props.onConfirm();
  }
  if (props.onClose) {
    props.onClose();
  }
};
</script>
