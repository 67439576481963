import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type { Ref } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { Project } from '@/shared/types/Project';
import { FreeSheetsNumber } from '@/shared/types/Subscription';
const initialProject: Project = {
  companyInProject: null,
  createdAt: '',
  createdByIdx: 0,
  delYn: '',
  deletedAt: null,
  deletedByIdx: null,
  endDt: '',
  name: '',
  projectIdx: 0,
  startDt: '',
  updatedAt: '',
  updatedByIdx: 0,
  isClient: false,
  isAdmin: false,
  totalMember: 0,
  sheets: 0,
};
export const projectStore = defineStore(
  'project',
  () => {
    const selectedProject: Ref<Project> = ref(cloneDeep(initialProject));
    const selectedProjectId = computed<number | undefined>(() => selectedProject.value?.projectIdx);
    const selfProjectIdx: Ref<string | number> = ref('');
    const isInAProject: Ref<boolean> = ref(true);

    const isWarningSubscription = computed(
      () =>
        selectedProject.value.totalMember >
        selectedProject.value?.sheets + FreeSheetsNumber.DEFAULT,
    );

    const reset = () => {
      selectedProject.value = cloneDeep(initialProject);
      selfProjectIdx.value = '';
      isInAProject.value = true;
    };

    return {
      selectedProject,
      selectedProjectId,
      selfProjectIdx,
      isInAProject,
      isWarningSubscription,
      reset,
    };
  },
  { persist: true },
);
