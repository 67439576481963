import timeline from '@/views/timeline/Timeline.vue';
import { projectPermission } from '@/middleware/permission';
export default [
  {
    path: '/timeline/:id',
    name: 'Timeline',
    component: () => import('@/views/timeline/Timeline.vue'),
    meta: { type: 'timeline', middleware: [projectPermission] },
  },
];
