import { Project } from '@/shared/types/Project';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type { Ref } from 'vue';

// Project list of current user
export const projectListStore = defineStore(
  'projectList',
  () => {
    const allProjects: Ref<Project[]> = ref([]);
    const isFetchProject: Ref<boolean> = ref(false);

    const professional = computed(() => allProjects.value.filter((project) => !project.isClient));
    const client = computed(() => allProjects.value.filter((project) => project.isClient));

    const reset = () => {
      allProjects.value = [];
      isFetchProject.value = false;
    };

    return { professional, client, reset, allProjects, isFetchProject };
  },
);
