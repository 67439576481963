import get from 'lodash/get';
import { createApp, App } from 'vue';

import BaseAlert from '@/components/Alert.vue';
import BaseToast from '@/components/Toast.vue';

const TYPE_POPUP = {
  alert: 'alert',
  toast: 'toast',
} as const;

const defaultConfigAlert: any = {
  modelValue: true,
  confirmText: '확인',
  closeText: '취소',
};
const defaultConfigToast: any = {
  isToast: true,
};

const getDefaultConfig = {
  [TYPE_POPUP.alert]: defaultConfigAlert,
  [TYPE_POPUP.toast]: defaultConfigToast,
};

const mainFunctionPopup = (
  config: any,
  type: (typeof TYPE_POPUP)[keyof typeof TYPE_POPUP] = TYPE_POPUP.alert,
): Promise<string> => {
  const currentConfig: any = { ...getDefaultConfig[type], ...config };
  return new Promise((resolve, reject) => {
    const mountEl = document.createElement('div');

    document.body.appendChild(mountEl);
    let componentPopup;
    switch (type) {
      case TYPE_POPUP.alert:
        componentPopup = BaseAlert;
        break;
      case TYPE_POPUP.toast:
        componentPopup = BaseToast;
        break;
      default:
        componentPopup = {};
    }
    const dialog: App = createApp(componentPopup, {
      ...currentConfig,
      onClose() {
        reject('rejected');
        dialog.unmount();
        mountEl.remove();

        if (typeof currentConfig.onClose === 'function') {
          currentConfig.onClose();
        }
      },
      onConfirm() {
        resolve('confirmed');
      },
    });
    if (type === TYPE_POPUP.toast) {
      const currentTimeout = get(currentConfig, 'timeout', 0) || 2000;

      setTimeout(() => {
        dialog.unmount();
        mountEl.remove();
      }, currentTimeout);
    }
    dialog.mount(mountEl);
  });
};

const aliasAlertFunction = (config: any) => mainFunctionPopup({ ...config }, TYPE_POPUP.alert);
const aliasToastFunction = (config: any) => mainFunctionPopup({ ...config }, TYPE_POPUP.toast);

export default {
  [TYPE_POPUP.alert]: aliasAlertFunction,
  [TYPE_POPUP.toast]: aliasToastFunction,
};
