import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { GlobalRole } from '@/shared/constans/index';
import { projectStore } from '@/stores/project';

export const roleStore = defineStore(
  'role',
  () => {
    const projectModule = projectStore();

    const userRoles = ref();
    const currentRole = ref();

    const isClient = ref(false);

    const isAdmin = computed(() => currentRole.value === GlobalRole.ADMIN);
    const isDepth1 = computed(() => currentRole.value === GlobalRole.DEPTH1);
    const isDepth2 = computed(() => currentRole.value === GlobalRole.DEPTH2);

    const checkPermission = (actionUser: any) => {
      if (!actionUser) return false;

      const role = currentRole.value;
      const selfProjectIdx = projectModule.selfProjectIdx;
      const { memberInProjectIdx, depth1Idx } = actionUser;
      if (isAdmin.value) return true;

      if (memberInProjectIdx === selfProjectIdx) return true;

      if (isDepth1.value && selfProjectIdx === depth1Idx) return true;

      return false;
    };

    const reset = () => {
      userRoles.value = [];
      currentRole.value = '';
      isClient.value = false;
    };

    return { userRoles, currentRole, isClient, checkPermission, reset, isAdmin };
  },
  { persist: true },
);
