import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Ref } from 'vue';
export const redirectStore = defineStore(
  'redirectStore',
  () => {
    const previousPath: Ref<string> = ref('');

    return { previousPath };
  },
  { persist: true },
);
