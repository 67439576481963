import { isEmpty, cloneDeep } from 'lodash';
import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { API_URL_DEV, API_URL_LOCAL, API_URL_PROD } from '../constans';
import { storeData, getData, removeData } from '@/utils/AsyncService';
import { userStore } from '@/stores/authStore';
import { projectStore } from '@/stores/project';
import { profileStore } from '@/stores/profile';
import { projectListStore } from '@/stores/projectList';
import { roleStore } from '@/stores/role';

import { redirectStore } from '@/stores/redirect';
import { apiStatus } from '@/shared/constans/index';

import router from '@/router';

let authModule;
let projectModule;
let profileModule;
let redirectModule;
let projectListModule;
let roleModule;

let refreshTokenPromise: any = null;

export function getRoutePath(url: string): string {
  const hashIndex = url.indexOf('/#/');

  if (hashIndex !== -1) {
    return url.substring(hashIndex + 3);
  }

  return ''; // Return undefined if '/#/' is not found
}
const resetInfoData = () => {
  projectModule = projectStore();
  profileModule = profileStore();
  projectListModule = projectListStore();
  roleModule = roleStore();
  authModule = userStore();
  projectModule.reset();
  profileModule.reset();
  projectListModule.reset();
  roleModule.reset();
  authModule.reset();
};

const redirectToLogin = () => {
  redirectModule = redirectStore();

  if (
    getRoutePath(window.location.href) &&
    getRoutePath(window.location.href) !== 'login' &&
    !window.location.href.includes('redirect')
  ) {
    redirectModule.previousPath = getRoutePath(window.location.href);
    router.push({ name: 'login', query: { redirect: redirectModule.previousPath } });
  }
};

const routeNotSetToken = [
  '/auth/login',
  '/auth/login/general',
  '/member/join',
  '/member/join/general',
  '/auth/email',
];

export class Http {
  private static _api: Http;

  private baseUrl: string = '';
  private axios!: AxiosInstance;

  private constructor(type: 'API') {
    if (type === 'API') {
      switch (process.env.VUE_APP_MODE) {
        case 'LOCAL':
          this.baseUrl = API_URL_LOCAL;
          break;
        case 'DEV':
          this.baseUrl = API_URL_DEV;
          break;
        case 'PROD':
          this.baseUrl = API_URL_PROD;
          break;
        default:
          this.baseUrl = API_URL_DEV;
          break;
      }
    }
    this.axios = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      } as AxiosRequestHeaders,
    });
    this.axios.interceptors.request.use(
      async (config: any) => {
        authModule = userStore();
        const userAuth = authModule.key;

        if ([...routeNotSetToken, '/auth/refresh'].includes(config.url)) return config;
        if (userAuth) {
          const token = userAuth || '';
          config.headers.Authorization = token;
          // if (process.env.VUE_APP_MODE === 'DEV') console.log('interceptor', token);
        }
        return config;
      },
      (err) => {
        return Promise.reject(err);
      },
    );
    this.axios.interceptors.response.use(
      (config) => {
        return config;
      },
      async (err) => {
        const {
          config,
          response: { status },
        } = err;
        authModule = userStore();

        if (status !== 401) return Promise.reject(err);

        if (routeNotSetToken.includes(config.url) || config.url === '/auth/refresh') {
          return Promise.reject(err);
        }

        if (!refreshTokenPromise) {
          refreshTokenPromise = this.refreshToken().then((token: string) => {
            refreshTokenPromise = null;
            return token;
          });
        }

        return refreshTokenPromise
          .then((token: string) => {
            if (!token) {
              resetInfoData();
              redirectToLogin();
            }
          })
          .catch((e: Error) => {
            resetInfoData();
            redirectToLogin();
          });
      },
    );
  }

  async get<T>(url: string, params?: T, responseType?: any): Promise<any> {
    const response = await this.axios
      .get(url, { params, responseType })
      .then((response) => response);

    return response;
  }
  async post<T>(url: string, data?: T, requestConfig?: AxiosRequestConfig): Promise<any> {
    const response = await this.axios.post(url, data, requestConfig).then((response) => response);
    return response;
  }
  async put<T>(url: string, data?: T): Promise<any> {
    const response = await this.axios.put(url, data).then((response) => response);

    return response;
  }
  async patch<T>(url: string, data?: T): Promise<any> {
    const response = await this.axios.patch(url, data).then((response) => response);

    return response;
  }
  async delete(url: string, data?: any): Promise<any> {
    const response = await this.axios.delete(url, { data }).then((response) => response);

    return response;
  }

  refreshToken(): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      profileModule = profileStore();
      authModule = userStore();
      authModule.login = false;
      try {
        if (authModule.refresh) {
          const res = await this.post(
            '/auth/refresh',
            {},
            {
              headers: {
                Authorization: authModule.refresh,
              },
            },
          );
          if (res.status === apiStatus.SUCCESS_CREATED) {
            authModule.login = true;
            authModule.key = res.data.key;
            authModule.expires = res.data.expires;
            authModule.refresh = res.data.refresh;
            resolve(authModule.key);
          }
        } else {
          resolve('');
        }
      } catch (error) {
        reject(error);
      }
    });
  }
  public static api() {
    if (!Http._api) {
      Http._api = new Http('API');
    }
    return Http._api;
  }

  public getBaseUrl() {
    return this.baseUrl;
  }
}
