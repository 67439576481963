import { projectStore } from '@/stores/project';
import { userStore } from '@/stores/authStore';

export function projectPermission({ to, next }: { to: any; next: any }) {
  const project = projectStore();
  const user = userStore();
  if (['setting', 'home'].includes(to.name)) return next();
  // if (!project.isInAProject && user.login) {
  //   return next({
  //     name: 'No project',
  //   });
  // }
  return next();
}
