import homeMain from "@/views/home/Home.vue";

export default [
  {
    path: '/home',
    name: 'homeMain',
    component: () => import('@/views/home/Home.vue'),
    children: [
      // {
      //   path: "/home/MA_01_01_01",
      //   name: "MA_01_01_01",
      //   component:()=> MA_01_01_01,
      // },
    ],
    meta: { type: 'home' },
  },
];
