export const parseQueryParams = (
  url: string,
  arrayFields: string[],
): Record<string, string | string[]> => {
  const urlObj = new URL(url);
  const queryString = urlObj.hash.split('?')[1] || '';
  const params = new URLSearchParams(queryString);
  const queryParams: Record<string, string | string[]> = {};

  params.forEach((value, key) => {
    if (arrayFields.includes(key)) {
      if (!Array.isArray(queryParams[key])) {
        queryParams[key] = [];
      }
      (queryParams[key] as string[]).push(value);
    } else {
      if (queryParams[key]) {
        queryParams[key] = Array.isArray(queryParams[key])
          ? [...(queryParams[key] as string[]), value]
          : [queryParams[key] as string, value];
      } else {
        queryParams[key] = value;
      }
    }
  });

  return queryParams;
};

export const setUndefinedIfEmpty = (obj: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (Array.isArray(value)) {
        result[key] = value.length > 0 ? value : undefined;
      } else if (typeof value === 'string') {
        result[key] = value.trim() ? value : undefined;
      } else {
        result[key] = value;
      }
    }
  }

  return result;
};

export const openUrl = (url: string, isBlank: boolean = false): void => {
  window.open(url, isBlank ? '_blank' : '');
};
