import { Http } from '@/shared/api/api';

export enum CommonHistoryType {
  CREATE = 1,
  CHANGE_TITLE = 2,
  CHANGE_DESCRIPTION = 3,
  CHANGE_APPROVER_ASSIGNEE = 4, // oldData { memberInProject: number, nickname: string} ; newData  { memberInProject: number, nickname: string}
  CHANGE_REPORTER = 5, // oldData { memberInProject: number, nickname: string} ; newData  { memberInProject: number, nickname: string}
  CHANGE_LINK = 6,
  CHANGE_SCHEDULE = 7, //oldData { startDt: string, endDt: string} ; newData { startDt: string, endDt: string}
  CHANGE_STATUS = 8,
  AUTO_CHANGE_STATUS = 9,
  CREATE_COMMENT = 13,
}

export enum MilestoneHistoryType {
  REQUEST_APPROVED = 10,
  APPROVED = 11,
  REJECT = 12,
}

export enum StoryHistoryType {
  CHANGE_ASSIGNEE = 4, // oldData { memberInProject: number, nickname: string}[] ; newData  { memberInProject: number, nickname: string}[]
  CHANGE_EPIC = 10, // oldData { epicIdx: number, title: string }; // newData { epicIdx: number, title: string }
}

enum HistoryEndpoints {
  HISTORY_STORY = '/history/story',
  HISTORY_MILESTONE = '/history/milestone',
  HISTORY_DATA = '/history-data',
}

interface Params {
  [key: string]: any;
}

interface Data {
  [key: string]: any;
}

export const getHistoryStory = async (params: Params) => {
  try {
    const response = await Http.api().get(HistoryEndpoints.HISTORY_STORY, params);
    return response;
  } catch (error) {
    console.error('Error get history story:', error);
    throw error;
  }
};
export const getHistoryMilestone = async (params: Params) => {
  try {
    const response = await Http.api().get(HistoryEndpoints.HISTORY_MILESTONE, params);
    return response;
  } catch (error) {
    console.error('Error get history milestone:', error);
    throw error;
  }
};

export const getHistoryData = async (params: Params) => {
  try {
    const response = await Http.api().get(
      `${HistoryEndpoints.HISTORY_DATA}/by-history-idx`,
      params,
    );
    return response;
  } catch (error) {
    console.error('Error get history story:', error);
    throw error;
  }
};
