import { Http } from '@/shared/api/api';

enum ProfileEndpoints {
  MEMBER_PROFILE = '/member/profile',
}

interface Params {
  [key: string]: any;
}

interface Data {
  [key: string]: any;
}

export const getMemberProfile = async (params: Params) => {
  try {
    const response = await Http.api().get(ProfileEndpoints.MEMBER_PROFILE, params);
    return response;
  } catch (error) {
    console.error('Error get request list:', error);
    throw error;
  }
};
