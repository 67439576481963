import { projectPermission } from '@/middleware/permission';

export default [
  // {
  //   path: "/setting",
  //   name: "setting",
  //   component:()=> setting,
  //   meta: { type: "setting" },
  // },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting/Setting.vue'),
    meta: { type: 'setting',middleware: [projectPermission] },
  },
];
