import moment from 'moment';
import { MilestoneHistoryType, StoryHistoryType, CommonHistoryType } from '@/api/history';
import { subscribe } from 'diagnostics_channel';
export const KR = {
  metadata: {
    title: 'EPOSO: IT 프로젝트 관리의 새로운 표준',
    description:
      'EPOSO로 IT 프로젝트의 투명성을 높이고, 일정 준수율을 개선하며, 이슈를 효과적으로 관리하세요. 실시간 모니터링, 직관적인 일정 관리, 체계적인 이슈 트래킹으로 프로젝트 성공률을 높입니다. EPOSO와 함께 더욱 쉽고 빠르게 IT 프로젝트를 운영하세요.',
    keywords:
      'IT 프로젝트 관리, 프로젝트 모니터링, 일정 관리, 이슈 트래킹, 팀 협업, 프로젝트 대시보드, 소프트웨어 개발 관리, 프로젝트 가시성, 프로젝트 지연 방지, 개발자 리소스 관리, IT 아웃소싱',
  },
  common: {
    confirm: '확인',
    client: '고객',
    professional: '전문가',
    delete: '삭제',
    link: '링크',
    edit: '수정',
    request: '요청',
    month: '월', // when english, no text
    save: '저장',
    cancel: '취소',
    add: '추가',
    textLengthLimit: (length: number) => `${length}자 이내 텍스트`,
    role: {
      manager: '관리자',
      professional: '전문가',
      client: '고객',
    },
    project: '프로젝트',
    epic: '에픽',
    justAfter: '방금',
    minLater: (n: number) => `${n}분 전`,
    hourLater: (n: number) => `${n}시간 전`,
    today: '오늘',
    history: '기록',
    none: '없음',
    unAssigned: '할당되지 않음',
    showMore: '더보기',
    showLess: '접기',
    startDate: '시작일',
    endDate: '마감일',
    formatDt: (date: any, initFormat: string, customFormat: string) =>
      initFormat
        ? moment
            .utc(date, initFormat)
            .local()
            .format(customFormat ? customFormat : 'YYYY.MM.DD')
        : moment(date).format(customFormat ? customFormat : 'YYYY.MM.DD'),
    deleteNoti: '모든 내용이 삭제되고 복구할 수 없습니다.정말 삭제하시겠습니까?',
    home: '홈',
    story: '스토리',
    milestone: '마일스톤',
    title: '제목',
    noData: '데이터가 없습니다.',
    setting: '환경',
    manager: '담당자',
    reporter: '보고자',
    detail: '내용',
    send: '발신',
    reject: '거부',
    sign: '서명',
    keyword: '키워드',
  },
  component: {
    join: {
      emailAuth: '이메일 인증',
      emailAuthMsg: `"인증하기" 클릭 시 이메일이 인증됩니다.`,
      auth: '인증하기',
      authComment: [
        '인증이 정상적으로 진행되지 않을 경우,',
        '아래 링크를 복사하여,인터넷창에 붙여넣어주세요.',
      ],
    },
    header: {
      convertClient: '고객으로 전환하였습니다.',
      convertProfessional: '전문가로 전환하였습니다.',
      noLoggedIn: '가입하고 서비스를 이용해보세요',
      loginJoin: '로그인/회원가입',
      dashboard: '대시보드',
      timeline: '일정',
      settlement: '정산',
      settings: '프로젝트 설정',
      profile: '프로필',
      logout: '로그아웃',
      addLink: '링크 추가',
    },
    noProject: {
      title: '프로젝트가 없습니다',
      text: '프로젝트에 참여하거나 새로 만들어보세요.',
      button: '홈으로 이동',
    },
    taskEditor: {
      thread: '스레드',
      comment: '댓글',
      commentPlaceholder: '댓글 내용을 입력합니다.',
      enterComment: '댓글을 입력하세요.',
      record: '기록',
      enterYourStory: 'Enter your story.',
      period: '진행 기간',
      progressStatus: '진행 상태',
      manager: '담당자',
      reporter: '보고자',
      approvalRequest: '승인 요청',
      approve: '승인',
      reject: '반려',
      aiTooltip: '제목을 입력하고 Generate AI 클릭시 제목에 맞는 스토리를 자동 작성합니다.',
      createSentence: '문장을 만들고 있습니다. 잠시만 기다려주세요.',
    },
    noComment: {
      text: '스레드를 작성해 보세요.',
      title: '작성된 스레드가 없습니다.',
    },
    taskComment: {
      confirmDelete: '삭제하시겠습니까?',
      confirmCloseComment: '수정 중인 내용이 있습니다. 정말 나가시겠습니까?',
    },
    historyList: {
      getTitleChange: (
        type: MilestoneHistoryType | StoryHistoryType | CommonHistoryType,
        typeTask: 'milestone' | 'story',
      ): string => {
        const storyText = {
          [CommonHistoryType.CREATE]: '스토리를 추가했습니다.',
          [CommonHistoryType.CHANGE_TITLE]: '제목을 변경했습니다.',
          [CommonHistoryType.CHANGE_DESCRIPTION]: '설명을 변경했습니다.',
          [CommonHistoryType.CHANGE_APPROVER_ASSIGNEE]: '담당자를 변경했습니다.',
          [CommonHistoryType.CHANGE_REPORTER]: '보고자를 변경했습니다.',
          [CommonHistoryType.CHANGE_LINK]: '링크를 수정했습니다.',
          [CommonHistoryType.CHANGE_SCHEDULE]: '진행기간을 변경했습니다.',
          [CommonHistoryType.CHANGE_STATUS]: '상태를 변경했습니다.',
          [CommonHistoryType.AUTO_CHANGE_STATUS]: '상태가 변경되었습니다.',
          [StoryHistoryType.CHANGE_EPIC]: '에픽을 변경했습니다.',
          [CommonHistoryType.CREATE_COMMENT]: '댓글을 입력했습니다.',
        };
        const milestoneText = {
          [CommonHistoryType.CREATE]: '마일스톤을 추가했습니다.',
          [CommonHistoryType.CHANGE_TITLE]: '제목을 변경했습니다.',
          [CommonHistoryType.CHANGE_DESCRIPTION]: '설명을 변경했습니다.',
          [CommonHistoryType.CHANGE_APPROVER_ASSIGNEE]: '담당자를 변경했습니다.',
          [CommonHistoryType.CHANGE_REPORTER]: '보고자를 변경했습니다.',
          [CommonHistoryType.CHANGE_LINK]: '링크를 수정했습니다.',
          [CommonHistoryType.CHANGE_SCHEDULE]: '진행기간을 변경했습니다. ',
          [CommonHistoryType.CHANGE_STATUS]: '상태를 변경했습니다.',
          [CommonHistoryType.AUTO_CHANGE_STATUS]: '상태가 변경되었습니다.',
          [MilestoneHistoryType.REQUEST_APPROVED]: '승인요청하였습니다.',
          [MilestoneHistoryType.APPROVED]: '승인하였습니다.',
          [MilestoneHistoryType.REJECT]: '반려하였습니다.',
          [CommonHistoryType.CREATE_COMMENT]: '댓글을 입력했습니다.',
        };
        return typeTask === 'milestone'
          ? milestoneText[type as MilestoneHistoryType | StoryHistoryType]
          : storyText[type as CommonHistoryType | StoryHistoryType];
      },
    },
    addRequest: {
      makeRequest: '요청하기',
      requestPlaceholder: '업무 요청 드립니다.',
    },
    addContract: {
      makeContract: '계약하기',
      recipient: '수신자',
      sender: '발신자',
    },
    timelineFilter: {
      milestoneStatus: '마일스톤 상태',
      storyStatus: '스토리 상태',
      resetFilter: '필터 초기화',
      keywordPlaceholder: '스토리 검색',
    },
    workingHour: {
      setWorkingHour: '근무 시간 설정',
      workingHour: '근무 시간',
      applyPeriod: '기간 일괄 적용',
    },
    warningSubscription: {
      warning:
        '프로젝트 시트가 부족합니다. 3일내로 구독 시트를 늘리거나, 담당자를 삭제해 주세요. 이후 프로젝트를 이용할 수 없습니다.',
    },
  },
  views: {
    home: {
      slideItem: [
        {
          title: `자유롭게 <span>시간을 선택</span>하여 일해 보세요.`,
          subtext: `프리랜서분들의 작업시간을 스토리에 설정할 수 있습니다.<br>일한 시간만큼 정산받아보세요.`,
        },
        {
          title: `프로젝트를 <span>어디에 맡길지</span> 막막한가요?`,
          subtext: `Eposo에 개발 공고를 올려보세요.<br>지원자 프로필을 확인하고 계약해 보세요.`,
        },
        {
          title: `<span>개발한 내용</span>이 생각한 것과 다른가요?`,
          subtext: `고객과 전문가간의 소통으로 개발 Risk를 줄여보세요.<br>Eposo에서는 단계별로 고객과 전문가가 협의하고 개발을 진행합니다.`,
        },
      ],
      slogan: 'IT 프로젝트 관리의 혁신',
      landingWrap: [
        'IT 프로젝트 관리의 불투명성, 일정 지연, 이슈 관리의 어려움을 EPOSO가 해결합니다.',
        'EPOSO는 실시간으로 변경되는 IT 프로젝트 관리의 표준을 제시합니다.',
      ],
      go: '무료로 프로젝트 만들러 가기',
      point: '포인트',
      onPrepared: '출시를 준비하고 있어요',
      onPreparedShort: '준비 중',
      pointText: [
        {
          title: '체계적인 이슈 트래킹',
          text: [
            '프로젝트 진행 시 발생한 문제를 즉시 등록하고 추적합니다.',
            '이슈 해결 과정을 투명하게 관리하여 프로젝트 리스크를 줄입니다.',
          ],
          comment: '지연된 이슈를 손쉽게 확인할 수 있어요.',
        },
        {
          title: '효율적인 일정 관리',
          text: [
            '직관적인 인터페이스로 복잡한 일정을 체계적으로 관리합니다.',
            '타임라인 시각화로 일정 관리가 더욱 쉬워집니다.',
          ],
          comment: '타임라인 시각화로 일정 관리가 더욱 쉬워집니다.',
        },
        {
          title: '투명한 프로젝트 관리',
          text: [
            '실시간 대시보드로 프로젝트 전체를 한눈에 파악합니다.',
            '진행 상황을 명확히 보고 신속한 의사결정이 가능해집니다.',
          ],
          comment: '고객의 승인을 받고, 작업을 시작해 서로의 고민을 줄일 수 있어요.',
        },
        {
          title: '정확한 인력 관리',
          text: [
            '인력 투입 현황과 참여율을 효과적으로 관리합니다.',
            '프로젝트 정산의 정확성과 효율성이 높아집니다.',
          ],
          comment: '프로젝트에서 일한 시간 만큼 간편하게 정산해 보세요.',
        },
        {
          title: '글로벌 인재 연결',
          text: [
            '다양한 지역의 우수한 인재들과 프로젝트를 연결합니다.',
            '프로젝트에 가장 적합한 인재를 쉽게 찾을 수 있습니다.',
          ],
          comment: '개발이 필요한 프로젝트를 올려 지원자를 모집해 보세요.',
        },
      ],
      pointImg: [
        require('@/assets/images/main/randing1.png'),
        require('@/assets/images/main/randing2.png'),
        require('@/assets/images/main/randing3.png'),
        require('@/assets/images/main/randing4.png'),
        require('@/assets/images/main/randing5.png'),
        require('@/assets/images/main/randing6.png'),
      ],
      tail: [
        'EPOSO로 프로젝트 가시성을 높이고, 관리 효율성을 극대화하세요.',
        '더 체계적인 프로젝트 관리의 시작, EPOSO와 함께하세요.',
      ],
    },
    login: {
      title: ['SNS로 간편하게 가입하고', '서비스를 이용하세요'],
      googleLogin: '구글 로그인 / 간편 회원 가입',
      emailLogin: '이메일 로그인',
      emailId: '이메일 ID',
      password: '비밀번호',
      join: '회원 가입',
      find: '계정 찾기',
      error: [
        '비밀번호가 올바르지 않습니다.',
        '일치하는 계정 정보가 없습니다.',
        '5회 이상 입력하여 잠금 처리 되었습니다. 비밀번호를 재설정해 주세요.',
        '로그인에 실패했습니다. 다시 시도해주세요.',
      ],
      toast: [
        '',
        '이메일이 발송되었습니다.',
        '잘못된 접근입니다.',
        '패스워드가 변경되었습니다.',
        '',
        '이메일 인증되었습니다.',
        '이메일 인증에 실패했습니다.',
        '탈퇴처리 되었습니다.',
        '이메일 로그인으로 해보세요.',
      ],
    },
    dashboard: {
      process: '진행 / 계획',
      milestone: '마일스톤',
      timeCost: '소모 시간',
      request: '요청사항',
      procRate: '공정률',
      approvalRequest: '승인요청',
      status: '상태',
      approvalDate: '승인일',
      approval: '승인',
      withdrawal: '반려',
      approved: '승인됨',
      noApprovalRequest: {
        title: '승인요청한 내용이 없습니다.',
        text: '마일스톤을 작성하고 고객에게 승인을 받을 수 있습니다.',
      },
      milestoneProc: '마일스톤 진행현황',
      noMilestones: {
        title: '마일스톤이 없습니다.',
        text: '마일스톤의 진행 기간이 표시됩니다.',
      },
      myRequests: '내 요청 서비스',
      assignee: '담당자',
      requestDate: '요청일',
      completeDate: '완료일',
      noRequests: {
        title: '요청한 서비스가 없습니다.',
        text: '프로젝트 관련자에게 업무를 요청해 보세요.',
      },
      myContract: '내 계약 현황',
      contract: '계약',
      index: '구분',
      target: '대상',
      sendDate: '발신일',
      contractDate: '계약일',
      send: '발신',
      receive: '수신',
      noContract: {
        title: '진행한 계약이 없습니다.',
        text: '프로젝트 관련자간 계약을 진행해 보세요.',
      },
    },
    timeline: {
      status: '상태',
      startDate: '시작일',
      endDate: '마감일',
      addMilestone: '마일스톤 추가',
      waitingStories: '대기 중인 스토리',
      addStory: '스토리 추가',
    },
    setting: {
      addProject: '프로젝트 추가',
      projectPeriod: '프로젝트 기간',
      startDate: '시작일',
      endDate: '마감일',
      startDateShorter: '시작일',
      endDateShorter: '마감일',
      dateError: '뒤에 있는 날짜가 앞의 날짜 보다 빠를 수 없습니다.',
      addAsMember: '담당자 이메일로 추가',
      member: '담당자',
      email: '이메일',
      company: '회사',
      role: '역할',
      assign: '담당',
      auth: '권한',
      unfold: '펼치기',
      epic: '에픽',
      weight: '가중치',
      addEpic: '에픽 추가',
      deleteProject: '프로젝트 삭제',
      askDelete: ['정말 삭제하시겠어요?', '프로젝트의 모든 내용이 삭제되고 복구할 수 없어요!'],
      notValidEmail: '올바른 이메일 형식이 아닙니다.',
      successAddMember: '멤버 추가에 성공했습니다.',
      epicLimit: '에픽 개수는 30개 이하로 설정됩니다.',
      deleteAsk: '정말 삭제하시겠습니까?',
      cardRegist: '카드 등록에 성공했습니다.',
      cardRegistFail: '카드 등록에 실패했습니다.',
      subscribe: '구독에 성공했습니다.',
      subscribeFail: '구독에 실패했습니다.',
      refund: '환불에 성공했습니다.',

      refundFail: '환불에 실패했습니다.',
      subscribeAdd: '구독 수 추가에 성공했습니다.',
      subscribeAddFail: '구독 수 추가에 실패했습니다.',
      usedSheets: '사용 시트', // Used Sheets
      sheets: '시트', // Sheets
      sheet: '시트', // Sheet
      free: '무료', // Free
      paid: '유료', // Paid
      subscription: '구독', // Subscription
      subscriptionChange: '구독 변경',
      paymentManagement: '결제 관리', // Payment Management
      registerSubscription: '구독 등록', // Register Subscription
      paymentInfo: '결제 정보', // Payment Information
      usingForFree: '무료 이용 중', // 현재 무료로 사용 중
      usingMonthly: '월 구독 중', // 현재 월 구독 중
      usingYearly: '연 구독 중', // 현재 연 구독 중
      subscriptionInfo: '구독정보', // Subscription Info
      paymentCard: '결제 카드', // Payment Card
      nextPaymentDate: '다음 결제일', // Next Payment Date
      paymentHistory: '결제 내역', // Payment History
      registerCard: '카드 등록', // Register Card
      notRegistered: '미등록', // No registered card

      agreeToTerms: '약관에 동의합니다', // I agree to the terms and conditions
      agreeToPayment: '결제에 동의합니다', // I agree to the payment
      noRecordForPayment: '결제한 내역이 없습니다.', // No record for payment
      cardNumber: '카드번호', // Card Number
      expirationDate: '유효기간', // Expiration Date
      birthDate: '생년월일', // Date of Birth
      corporateCard: '법인카드', // Corporate Card
      ownerName: '소유주명', // Owner Name
      password: '비밀번호', // Password

      partial: '부분', // Partial
      full: '전체', // Full
      cancel: '취소', // Cancel
      save: '저장', // Save
      monthWord: '월', // Month
      yearWord: '년', // Year
      subscribeWord: '구독', // Subscribe

      subscriptionSheets: '구독 시트', // Subscription Sheets
      paymentMethod: '결제 방법', // Payment Method
      monthlyPlanPrice: '월 구독료', // Monthly Plan
      yearlyPlanPrice: '연 구독료', // Yearly Plan
      monthlySubscription: '월 결제', // Monthly Subscription
      yearlySubscription: '연 선 결제', // Yearly Subscription
      subscriptionPeriod: '구독 기간', // Subscription Period
      paymentAmount: '결제 금액', // Payment Amount
      addtionalWord: '추가', // Addtional
      addedCost: '추가 결제 금액', // Added cost
      discountWord: '할인', // Discount
      refundHistory: '환불 내역', // Refund History
      refundWord: '환불', // Refund
      amountWord: '금액', // Amount
      payWord: '결제', // Pay
      priceWord: '금액', // Price
      totalRefundAmount: '총 환불 금액', // Total Refund Amount
      termLanguage: 'KR', // Term Language
      currency: '원', // Currency
      sheetsAvailable: '시트 사용 가능', // Sheets Available
      total: '총',
      day: '일',
      days: '일',
      use: '사용',
      used: '사용',
    },
    profile: {
      editPhoto: '사진수정',
      nickname: '별명',
      personalColor: '퍼스널컬러',
      email: '이메일',
      authEmail: '이메일 인증',
      name: '이름',
      phone: '휴대폰번호',
      numberOnly: '숫자만 입력해주세요.',
      birth: '생년월일',
      gender: '성별',
      male: '남성',
      female: '여성',
      task: '업무',
      job: '직무',
      taskType: '업무성격',
      freelancer: '프리랜서',
      officer: '정규직',
      hopeLaborDay: '희망일당',
      pricePerDay: '천원 / 8시간',
      company: '회사',
      career: '경력',
      deleteCareer: '경력 삭제하기',
      companyName: '회사명',
      workPeriod: '근무 기간',
      dateError: '종료일이 시작일보다 빠를 수 없습니다.',
      level: '직급/직무',
      salaryYear: '연봉(천원)',
      addCareer: '경력 추가하기',
      setAccount: '계정 설정',
      addProfile: '프로필 등록',
      account: '계정',
      withdrawAccount: '회원 탈퇴',
      resetPassword: '비밀번호 재설정',
      agreeMarketing: '마케팅 동의',
      agreedAt: '동의일시',
      notification: '알림',
      notiAll: '전체 알림',
      notiAllMsg: '모든 알림 메시지를 수신합니다.',
      notiEmail: '이메일 알림',
      notiEmailMsg: '설정된 Email로 알림을 수신합니다.',
      notiNewTask: '새 업무 알림',
      notiNewTaskMsg: '담당자로 지정되었을 경우 알림을 수신합니다.',
      notiDelayed: '지연 알림',
      notiDelayedMsg: '지연된 스토리가 있을 경우 알림을 수신합니다.',
      notiReq: '업무 요청 알림',
      notiReqMsg: '나에게 새로운 요청이 생길 경우 알림을 수신합니다.',
      withdrawAsk: (nickname: string) => `${nickname}님, 정말 탈퇴하시겠습니까?`,
      withdrawMsg: [
        '지금 탈퇴하시면 진행 중이거나 진행 예정 작업들에 대해 문제가 발생할 경우,',
        '고객사에서 손해배상을 청구할 수 있습니다.',
        '지금 탈퇴하시면 현재 계정에 있는 데이터가 모두 사라져요.',
        '추후에 동일 계정으로 재가입하셔도 데이터는 복구되지 않습니다.',
      ],
      giveFeedback: '피드백 부탁드려요!',
      feedbackPlaceholder:
        '서비스 탈퇴 사유에 대해 말씀해 주시겠어요?&#10;아쉬운 부분에 대해 피드백 주시면 서비스 개선에 큰 도움이 됩니다.',
      withdrawCheckbox: '회원 탈퇴 유의사항을 확인하였으며 동의합니다.',
      resetPasswordSent: '비밀번호 재설정 이메일이 발송되었습니다. 이메일을 확인해주세요.',
      success: '프로필 등록에 성공하였습니다.',
      askDelete: '정말 삭제하시겠습니까?',
      careerFull: '경력을 더이상 추가하실 수 없습니다.',
    },
  },
  status: {
    timeline: {
      completed: '완료',
      inProgress: '진행',
      pending: '대기',
      delayed: '지연',
      considering: '검토중',
      rejected: '거부',
      approving: '승인중',
      approved: '승인완료',
    },
    request: {
      awaiting: '대기',
      inProgress: '진행',
      completed: '완료',
    },
    contract: {
      completed: '완료',
      inProgress: '진행',
      rejected: '거부',
    },
  },
};
