import { defineStore } from 'pinia';
import { ref } from 'vue';

export const userStore = defineStore(
  'userAuth',
  () => {
    const login = ref(false);
    const key = ref('');
    const expires = ref('');
    const refresh = ref('');

    const reset = () => {
      login.value = false;
      key.value = '';
      expires.value = '';
      refresh.value = '';
    };

    return { login, key, expires, refresh, reset };
  },
  { persist: true },
);
